* {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}


.center{
    display: flex;
    justify-content: center;
    /*flex-direction: column;*/

    height:100vh;
}

.centering{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.spacingVertical{
    margin-top: 10px;
    display: flex;
    flex-direction: row;


}

.spacingHorizontal{
    margin-right: 10px;

}